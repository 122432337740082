import GenerCampos from '../generCampos'

const generCampos = new GenerCampos()

const INGRESOS_PERSONA_ID = 15
const EGRESOS_PERSONA_ID = 13

var informePruebaPoligrafiaEspecifico = [
    {
       titulo: 'INFORMACIÓN PERSONAL',
       campos: [
          {
             label: 'FOTO DEL USUARIO',
             tipo: 'archivo',
             permitido: 'image/png, image/jpeg',
             valor: null,
             ignorar: false,
             obligatorio: false,
             justify: 'center'
          },
          {
             label: 'Nombres y Apellidos',
             tipo: 'texto',
             valor: null,
             idCampo:6,
             ignorar: false
          },
          {
             label: 'Número de Cédula',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Lugar de Expedición',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Fecha de Expedición',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'Lugar de Nacimiento',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Fecha de Nacimiento',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'Edad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Estado Civil',
             tipo: 'desplegable',
             lista: ['Casado', 'Soltero', 'Unión libre'],
             valor: null,
             ignorar: false
          },
          {
             label: 'Dirección Residencial',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Barrio / Localidad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Ciudad',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Teléfono',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Fecha Examen Poligráfico',
             tipo: 'fecha',
             valor: null,
             ignorar: false
          },
          {
             label: 'No. Polígrafos Anteriores',
             tipo: 'texto',
             valor: null,
             ignorar: false
          },
          {
             label: 'Cargo al que Aspira',
             tipo: 'texto',
             idCampo:5,
             valor: null,
             ignorar: false
          },
          {
             label: 'Compañía Solicitante',
             tipo: 'texto',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'PROPÓSITO GENERAL DEL EXAMEN ',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Previo requerimiento del contratante y contando con la
                   autorización de la persona se realizó una evaluación técnico-científica denominada `
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'texto',
                      valor: null,
                      wx: 3
                   }
                }
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'ESTADO DE SALUD',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: [],
             listaValores: [
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Cómo califica su estado de salud?', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Actualmente consume usted algún medicamento?', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Ha tenido hospitalizaciones recientes?', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Sufre de alguna enfermedad diagnosticada?', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Ha estado en tratamientos psicológicos o psiquiátricos?', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Le han realizado cirugías?', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
                [
                   {label: '', tipo: 'textoLargo', valor:'¿Ha tenido accidentes laborales?', noEditable: true},
                   {label: '', tipo: 'textoLargo', valor: null}
                ],
             ],
             formato: [],
             columnasPeque: [1],
             noEditable: true,
             ignorar: false
          },
          {
             label: '',
             tipo: 'inf',
             valor: 'Mencionar el detalle de lo que la persona manifieste en las preguntas que haya mencionado si',
             ignorar: false
          },
          {
             label: 'Observaciones',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          },
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: 'De acuerdo con lo mencionado frente al estado de salud físico y psicológico, se consideró a la persona',
                },
                {
                   tipo: 'campo',
                   campo: generCampos.darCampoDesplegable("",null,-1,["idóneo","no idóneo"], false).toJSON()
                },
                {
                   tipo: 'texto',
                   texto: 'para el desarrollo de su prueba.'
                }
             ],
             ignorar: false
          }
       ]
    },
    {
       titulo: 'HISTORIA FAMILIAR',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: ['PARENTESCO','NOMBRES','EDAD','OCUPACIÓN','CIUDAD DE RESIDENCIA','CONVIVE CON LA PERSONA'],
             formato: [
                {label: 'PARENTESCO', tipo: 'texto',valor: null},
                {label: 'NOMBRES', tipo: 'texto',valor: null},
                {label: 'EDAD', tipo: 'texto',valor: null},
                {label: 'OCUPACIÓN', tipo: 'texto',valor: null},
                {label: 'CIUDAD DE RESIDENCIA', tipo: 'texto',valor: null},
                {label: 'CONVIVE CON LA PERSONA', tipo: 'texto',valor: null},
             ],
             listaValores: [],
             ignorar: false
          },
          {
             label: '',
             tipo: 'inf',
             valor: 'Adicional a lo anterior la persona refiere que vive con',
             ignorar: false
          },
          {
             label: '',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
      titulo: 'INFORMACIÓN FINANCIERA Y PATRIMONIAL',
      campos: [
         generCampos.darCampoTabla(
           "INGRESOS PERSONA",
           ['',''],
           [],
           [
              [
               generCampos.darCampoTexto("","Ultimo Ingreso Mensual: ",null,true,false).toJSON(),
               generCampos.darCampoValoraSumar("",INGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
              ],
              [
               generCampos.darCampoTexto("","Ingresos Adicionales: ",null,true,false).toJSON(),
               generCampos.darCampoValoraSumar("",INGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
              ],
              [
               generCampos.darCampoTexto("","Total Ingresos",null,true,false).toJSON(),
               generCampos.darCampoResultadoSuma('',INGRESOS_PERSONA_ID).setSimbolo('$').toJSON()
              ]
           ],
           true,
           false
         ).toJSON(),
         generCampos.darCampoTabla(
           "EGRESOS PERSONA",
           ['',''],
           [],
           [
               [
                  generCampos.darCampoTexto("","Gastos Financieros",0,true,false).toJSON(),
                  generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
               ],
               [
                  generCampos.darCampoTexto("","Arriendo",0,true,false).toJSON(),
                  generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
               ],
               [
                  generCampos.darCampoTexto("","Servicios",0,true,false).toJSON(),
                  generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
               ],
               [
                  generCampos.darCampoTexto("","Alimentación",0,true,false).toJSON(),
                  generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
               ],
               [
                  generCampos.darCampoTexto("","Transporte",0,true,false).toJSON(),
                  generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
               ],
               [
                  generCampos.darCampoTexto("","Gastos personales",0,true,false).toJSON(),
                  generCampos.darCampoValoraSumar("",EGRESOS_PERSONA_ID,null).setSimbolo('$').toJSON()
               ],
               [
                  generCampos.darCampoTexto("","EGRESOS MENSUALES TOTAL",0,true,false).toJSON(),
                  generCampos.darCampoResultadoSuma('',EGRESOS_PERSONA_ID).setSimbolo('$').toJSON()
               ]
            ],
           true,
           false
        ).toJSON(),
        generCampos.darCampoCompletarTexto('',[
           'OBSERVACIONES: ',
           'Después de registrada la información financiera de la persona, menciona que los gastos ',
           generCampos.darCampoDesplegable('',null,-1,['no son compartidos' , 'son compartidos']).toJSON(),
           generCampos.darCampoTexto('','con su…, adicional la persona manifiesta que',-1,false,false).setWx(3).toJSON()
        ]).toJSON(),
     ]
   },
   {
      titulo: 'OBLIGACIONES CREDITICIAS',
      campos: [
         {
            label: '',
            tipo: 'tabla',
            cabeceras: [],
            listaValores: [
               [
                  {label: 'Créditos',tipo: 'textoLargo',valor: null,noEditable: true},
                  {label: '',tipo: 'textoLargo',valor: 'Reportó que '}
               ],
               [
                  {label: 'Tarjetas de Crédito',tipo: 'textoLargo',valor: null,noEditable: true},
                  {label: '',tipo: 'textoLargo',valor: 'Refirió que '}
               ],
               [
                  {label: 'Registros en centrales de riesgo financiero',tipo: 'textoLargo',valor: null,noEditable: true},
                  {label: '',tipo: 'textoLargo',valor: 'Expresó que '}
               ],
               [
                  {label: 'Bienes muebles e inmuebles a su nombre',tipo: 'textoLargo',valor: null,noEditable: true},
                  {label: '',tipo: 'textoLargo',valor: 'Comentó que'}
               ],
            ],
            formato: [],
            noEditable: true,
            ignorar: false,
         },
         {
            label: 'OBSERVACIONES',
            tipo: 'textoLargo',
            valor: 'Aparte de lo anterior la persona que cancela un crédito xxx a nombre de su esposa',
            ignorar: false
         }
      ]
    },
   //  {
   //     titulo: 'HISTORIA LABORAL',
   //     campos: [
   //        {
   //           label: '',
   //           tipo:'duplicaCampos',
   //           campo: {
   //              label: '',
   //              tipo: 'tabla',
   //              cabeceras: [],
   //              listaValores: [
   //                 [
   //                    {label: 'Empresa', tipo: 'texto', valor: '', noEditable: true},
   //                    {label: '', tipo: 'texto', valor: null}
   //                 ],
   //                 [
   //                    {label: 'Fecha de ingreso', tipo: 'texto', valor: '', noEditable: true},
   //                    {label: '', tipo: 'fecha', valor: null}
   //                 ],
   //                 [
   //                    {label: 'Fecha de retiro', tipo: 'texto', valor: '', noEditable: true},
   //                    {label: '', tipo: 'fecha', valor: null}
   //                 ],
   //                 [
   //                    {label: 'Último cargo ', tipo: 'texto', valor: '', noEditable: true},
   //                    {label: '', tipo: 'texto', valor: null}
   //                 ],
   //                 [
   //                    {label: 'Salario', tipo: 'texto', valor: '', noEditable: true},
   //                    {label: '', tipo: 'numero', simbolo: '$', valor: null}
   //                 ],
   //                 [
   //                    {label: 'Último jefe inmediato', tipo: 'texto', valor: '', noEditable: true},
   //                    {label: '', tipo: 'texto', valor: null}
   //                 ],
   //                 [
   //                    {label: 'Motivo del retiro', tipo: 'texto', valor: '', noEditable: true},
   //                    {label: '', tipo: 'texto', valor: null}
   //                 ],
   //                 [
   //                    {label: 'Informacion adicional', tipo: 'texto', valor: '', noEditable: true},
   //                    {label: '', tipo: 'texto', valor: null}
   //                 ],
   //              ],
   //              formato: [],
   //              noEditable: true,
   //              ignorar: false
   //           },
   //           listadoCampos: [
   //              {
   //                 label: '',
   //                 tipo: 'tabla',
   //                 cabeceras: [],
   //                 listaValores: [
   //                    [
   //                       {label: 'Empresa', tipo: 'texto', valor: '', noEditable: true},
   //                       {label: '', tipo: 'texto', valor: null}
   //                    ],
   //                    [
   //                       {label: 'Fecha de ingreso', tipo: 'texto', valor: '', noEditable: true},
   //                       {label: '', tipo: 'fecha', valor: null}
   //                    ],
   //                    [
   //                       {label: 'Fecha de retiro', tipo: 'texto', valor: '', noEditable: true},
   //                       {label: '', tipo: 'fecha', valor: null}
   //                    ],
   //                    [
   //                       {label: 'Último cargo ', tipo: 'texto', valor: '', noEditable: true},
   //                       {label: '', tipo: 'texto', valor: null}
   //                    ],
   //                    [
   //                       {label: 'Salario', tipo: 'texto', valor: '', noEditable: true},
   //                       {label: '', tipo: 'numero', simbolo: '$', valor: null}
   //                    ],
   //                    [
   //                       {label: 'Último jefe inmediato', tipo: 'texto', valor: '', noEditable: true},
   //                       {label: '', tipo: 'texto', valor: null}
   //                    ],
   //                    [
   //                       {label: 'Motivo del retiro', tipo: 'texto', valor: '', noEditable: true},
   //                       {label: '', tipo: 'texto', valor: null}
   //                    ],
   //                    [
   //                       {label: 'Informacion adicional', tipo: 'texto', valor: '', noEditable: true},
   //                       {label: '', tipo: 'texto', valor: null}
   //                    ],
   //                 ],
   //                 formato: [],
   //                 noEditable: true,
   //                 ignorar: false
   //              },
   //           ],
   //           ignorar: false
   //        },
   //        {
   //           label: '',
   //           tipo: 'inf',
   //           valor: 'Observaciones',
   //           ignorar: false
   //        },
   //        {
   //           label: '',
   //           tipo: 'textoLargo',
   //           valor: null,
   //           ignorar: false
   //        }
   //     ]
   //  },
    {
       titulo: 'CARGO ACTUAL',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             cabeceras: [],
             listaValores: [
                [
                   {label: 'Empresa', tipo: 'texto', valor: '', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: 'Fecha de ingreso', tipo: 'texto', valor: '', noEditable: true},
                   {label: '', tipo: 'fecha', valor: null}
                ],
                [
                   {label: 'Ultimo cargo', tipo: 'texto', valor: '', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ],
                [
                   {label: 'Jefe inmediato', tipo: 'texto', valor: '', noEditable: true},
                   {label: '', tipo: 'texto', valor: null}
                ]
             ],
             formato: [],
             noEditable: true,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'FUNCIONES DESEMPEÑADAS EN EL CARGO',
       campos: [
          {
             label: '',
             tipo: 'textoLargo',
             valor: 'Manifestó que en el cargo actual realiza ',
             ignorar: false
          }
       ]
    },
    {
       titulo: 'RELATO BREVE DE LOS HECHOS EFECTUADO POR LA PERSONA',
       campos: [
          {
             label: '',
             tipo: 'textoLargo',
             valor: 'La persona inicia el relato diciendo que ',
             ignorar: false
          }
       ]
    },
    {
      titulo: 'PREGUNTAS RELACIONADAS CON LOS HECHOS',
      campos: [
         generCampos.darCampoEntrevista(true).newPregunta(
            '-	¿Cuál es su hipótesis de los hechos presentados?: ', null
         ).newPregunta(
            '-	¿Existe alguna cámara que enfoque el lugar xxxx donde xxxx ?: ', null
         ).newPregunta(
            '-	¿En el tiempo que usted ha trabajado en xxxx, se habían presentado este tipo de hechos?: ',null
         ).newPregunta(
            '-	¿Tiene usted sospecha de quien o quienes pueden estar involucrado en xxxxx presentado en xxxxxxx?: ',null
         ).newPregunta(
            '-	¿Sabía usted con anterioridad que iba a presentarse el xxxxx de xxxxxx?: ',null
         ).newPregunta(
            '-	¿Conoce usted las personas que ingresaron a la empresa a realizar el xxxxxx?:',null
         ).newPregunta(
            '-	¿Planeó usted el robo de la xxxxx ?: ',null
         ).newPregunta(
            '-	¿Tenía usted autorización para entregar lxxxxxxxx?: ',null
         ).newPregunta(
            '-	¿Alguna persona o compañero de trabajo le hizo alguna propuesta para comete actos ilícitos en xxxxxxx?: ',null
         ).newPregunta(
            '-	¿Se puso usted de acuerdo con alguien para entregar las llaves del xxxxx para que se cometiera xxxxx?: ',null
         ).newPregunta(
            '-	¿Participo usted directa o indirectamente en el xxxxxxxx?: ',null
         ).newPregunta(
            '-	¿Suministro usted alguna información para que ingresaran a xxxxxxxxxx?: ',null
         ).newPregunta(
            '-	¿Qué piensa de la seguridad de xxxxxx?: ',null
         ).newPregunta(
            '-	¿Sea beneficiado o espera beneficiarse de alguna forma por el xxxxx de xxxxxxxxxx?: ',null
         ).newPregunta(
            '-	¿Omitió usted los procedimiento o protocolos para permitir que ingresaran personas a xxxxxxxxxx?: ',null
         ).newPregunta(
            '-	¿Hay alguna cámara o persona que lo pueda señalar como cómplice o participe de este hecho?: ',null
         ).newPregunta(
            '-	¿En algún de los empleos que ha tenido podrían darnos una referencia negativa suya?: ',null
         ).newPregunta(
            '-	¿Si usted encontrara a un compañero de trabajo haciendo actividades ilícitas, que reacción tomaría?: ',null
         ).newPregunta(
            '-	¿Tiene usted algún vinculo o nexo con personas que actúen en la delincuencia?:  ',null
         ).newPregunta(
            '-	¿Desea agregar algo mas que sea importante para esta investigación?: ',null
         ).textoPegados(false).toJSON()
      ]
    },
    {
       titulo: 'ADMISIONES OBTENIDAS DURANTE LA PRUEBA',
       campos: [
          {
             label: '',
             tipo: 'inf',
             valor: 'Agregar las admisiones mencionadas en el transcurso de la prueba ',
             ignorar: false
          },
          {
             label: '',
             tipo: 'textoLargo',
             valor: null,
             ignorar: false
          }
       ]
    },
    {
       titulo: 'TEMAS A EVALUAR',
       campos: [
          {
             label: '',
             tipo: 'tabla',
             columnasPeque: [0],
             formato: [
                { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                { label: '', tipo: 'texto', valor: null}
             ],
             cabeceras: [],
             listaValores: [
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
                [
                   { label: '', tipo: 'texto', simbolo:'R', valor: null, noEditable: true},
                   { label: '', tipo: 'texto', valor: null}
                ],
             ],
             ignorar: false,
             autoIncrementable: true
          },
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Cada una de las preguntas anteriormente relacionadas, fueron explicadas con anterioridad a
                   la evaluación, las cuales se operacionalizaron, aclararon y enmarcaron dentro del contexto de lo
                   que se quería indagar, de tal forma que la evaluada las explicó y manifestó entenderlas con
                   claridad.`
                }
             ],
             ignorar: false
          }
       ]
    },
    {
        titulo: 'PARÁMETROS CIENTÍFICOS',
        campos: [
           {
           label: '',
           tipo: 'completarTexto',
           secuencias: [
              {
                 tipo: 'texto',
                 texto: `El examen poligráfico es realizado con un instrumento sistematizado marca `
              },
              {
                  tipo: 'campo',
                  campo: {
                     label: '',
                     tipo: 'texto',
                     valor: null
                  }
              },
              {
                  tipo: 'texto',
                  texto: `, teniendo en cuenta las normas internacionales estipuladas por la Asociación Americana de Poligrafistas (APA),
                  en el cual los patrones de verdad y decepción se establecen a través de la técnica conocida como `
              },
              {
                  tipo: 'campo',
                  campo: {
                     label: '',
                     tipo: 'texto',
                     valor: null
                  }
               },
               {
                  tipo: 'texto',
                  texto: ` que incluye un formato de preguntas neutrales, comparativas y relevantes.`
              },
              {
                 tipo: 'br'
              },
              {
                 tipo: 'br'
              },
              {
                 tipo: 'texto',
                 texto: `El examen es ejecutado por un Poligrafista profesional certificado; una vez realizada la entrevista
                 previa, se procede a explicar los componentes del polígrafo CARDIO, NEUMÓGRAFO, EDA y SENSOR DE MOVIMIENTO,
                 así mismo se le informó que la evaluación es de carácter VOLUNTARIO, seguidamente procede a diligenciar la
                 autorización correspondiente para iniciar el examen y afirma haber entendido todo el procedimiento al que se
                 somete.`
              }
           ],
           ignorar: false
        },
        {
           label: '',
           tipo: 'evaluacionPoligrafia',
           cabeceras: ['R-1','R-2','R-3','R-4','GRAN TOTAL'],
           listaValores: [
                 { label: '', tipo: 'texto', valor: ''},
                 { label: '', tipo: 'texto', valor: ''},
                 { label: '', tipo: 'texto', valor: ''},
                 { label: '', tipo: 'texto', valor: ''},
                 { label: '', tipo: 'texto', valor: ''}
              ],
              listaOpciones: [
                 { label: '', tipo: 'desplegable', valor: '', lista: ['NDI','DI','NOP','INC']},
                 { label: '', tipo: 'desplegable', valor: '', lista: ['NDI','DI','NOP','INC']},
                 { label: '', tipo: 'desplegable', valor: '', lista: ['NDI','DI','NOP','INC']},
                 { label: '', tipo: 'desplegable', valor: '', lista: ['NDI','DI','NOP','INC']},
                 { label: '', tipo: 'texto', valor: ''}
              ],
              campoPuntuacion: {
                 label: '',
                 tipo: 'texto',
                 valor: null,
              },
              campoOpcionGeneral: {
                 label: '',
                 tipo: 'desplegable',
                 lista: ['NDI','DI','NOP','INC'],
                 valor: null
              },
              pintar: false,
              ignorar: false
        }
     ]
    },
    {
       titulo: 'RESULTADO DEL EXAMEN',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Usando el ESS (Empirical Scoring System), que es un protocolo basado
                   en la evidencia, normado y estandarizado para el análisis de datos de la prueba.
                   Los resultados estadísticos y matemáticos apoyan la conclusión que `
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'texto',
                      idDependiente: 6,
                      noEditable: true,
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: `, quien desempeña el cargo de `
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      idDependiente: 5,
                      noEditable: true,
                      tipo: 'texto',
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: ', '
                },
                {
                   tipo: 'campo',
                   campo: {
                      label: '',
                      tipo: 'desplegable',
                      lista: ['NO PRESENTA TENSIÓN O ENGAÑO EN LA RESPUESTA  (NDI)', 'PRESENTA TENSIÓN O ENGAÑO EN LA RESPUESTA  (DI)'],
                      valor: null
                   }
                },
                {
                   tipo: 'texto',
                   texto: `respuestas fisiológicas significativas de falta de veracidad a las
                   preguntas relevantes de este examen.`
                },
                {
                    tipo: 'campo',
                    campo: {
                       label: '',
                       tipo: 'texto',
                       valor: null
                    }
                }
             ],
             ignorar: false
          },
          {
              label: 'FIRMA',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false
           }
       ]
    },
    {
       titulo: 'NOTAS ACLARATORIAS',
       campos: [
          {
             label: '',
             tipo: 'completarTexto',
             secuencias: [
                {
                   tipo: 'texto',
                   texto: `Los resultados de las pruebas poligráficas siempre serán entregados
                   al cliente que contrató los servicios. Cada contratante está en plena autonomía
                   de tomar las decisiones laborales y/o judiciales que considere necesarias para
                   cada situación. Sin embargo, se recuerda que en Colombia la prueba poligráfica
                   no se constituye en prueba pericial o judicial, es sólo un indicio. Según la
                   legislación laboral colombiana, en NINGÚN momento se podrá despedir a una persona
                   por los resultados del examen de polígrafo, pues no se considera justa causa.
                   En Colombia, los resultados de la evaluación poligráfica se utilizan para la
                   investigación interna de las organizaciones; ya sea en la verificación de
                   antecedentes, idoneidad en cargos o en la investigación de algún siniestro. En
                   ningún momento, la información suministrada por el evaluado será utilizado por el
                   examinador o contratante para acusarlo judicialmente.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Los resultados obtenidos en la evaluación poligráfica, son de carácter
                   confidencial y la divulgación de los mismos, por parte del contratante a personas
                   que no hayan sido autorizadas por el evaluado, se considera una violación a su
                   privacidad.`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `En la evaluación poligráfica se miden y se comparan reacciones fisiológicas de
                   cada ser humano. A cada individuo se le determina un estándar y luego se mide y se
                   compara matemática y estadísticamente. Después del este análisis se emite un concepto
                   basado en la ponderación de las respuestas fisiológicas y se determina, si las reacciones
                   fisiológicas de la persona, son típicas de una persona que miente o por el contrario
                   son típicas de una persona que dice la verdad. Debido a que la mayoría de las
                   investigaciones en poligrafía, las han desarrollado agencias gubernamentales y
                   universidades americanas, los términos se han globalizado según su traducción del inglés:`
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `NDI.  esto quiere decir que la persona no presentó reacciones fisiológicas significativas de engaño.
                   DI. esto quiere decir que la persona si presentó reacciones fisiológicas significativas de engaño, en otras palabras,
                   que mintió.
                   Cuando los valores estadísticos y matemáticos no sobrepasan el umbral mínimo de confiabilidad
                   de la prueba del 91%,
                   se tiene que emitir un concepto de Inconcluso (INC) o No opinión (NOP).
                   Inconcluso hace referencia a que se tomó una muestra fisiológica adecuada, pero en la
                   ponderación no se logró alcanzar un valor estadístico suficiente para emitir un concepto.
                   No Opinión, hace referencia a que no se pudo tomar una muestra fisiológica
                   adecuada de la prueba y por ello no se puede emitir un concepto alguno.
                   Reseña Bibliográfica.
                   Tomado de Terminology Reference for the Science of Psychophysiological Detection
                   of Deception. American Polygraph Association 3rd Edition, 2012 by Donald Krapohl,
                   Mark Handler Shirley Sturm.
                   `
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'br'
                },
                {
                   tipo: 'texto',
                   texto: `Determinación de estado psicofisiológico:
                   Idóneo: En condiciones adecuadas para el desarrollo de la prueba
                   Marginal: Que presenta alguna situación médica o psicológica, que, aunque
                   no impide el desarrollo de la prueba, podría afectar la confiabilidad.
                   Sin embargo, se puede desarrollar la prueba y obtener información de interés
                   o evitar un ilícito.
                   No idóneo: Que el evaluado no está en condiciones físicas o psicológicas para
                   presentar el examen.
                   `
                }
             ],
             ignorar: false
          }
       ]
    },
    {
        titulo: 'DOCUMENTOS',
        campos: [
          {
            tipo: 'saltoPagina'
          },
           {
              label: 'AUTORIZACIÓN',
              tipo: 'archivo',
              permitido: 'image/png, image/jpeg',
              valor: null,
              ignorar: false,
              obligatorio: false
           }
        ]
     }
  ]

export default(informePruebaPoligrafiaEspecifico)
