<template>
  <FullBoxVue class="shadowHover" :key="componentKey">
    <div class="formContent">
      <h1>Registrar empresa</h1>
      <br />
      <hr />
      <br /><br />
      <middle-box>
        <BlitzForm
          :schema="schemaRegister"
          v-model="valuesRegister"
          gridGap="0"
          placeholderPosition="left"
        />
        <b>Nota: </b>Los días de mes van entre el 1 y el 28.
        <br />

        <BlitzForm
          :schema="schemaUbicacion"
          v-model="valuesUbicacion"
          :key="componentKeyUbicacion"
          gridGap="0"
          placeholderPosition="left"
        />

        <v-switch v-model="valuesRegister.ordenCompra" :label="`Orden de compra`" style="width: 300px; float: left;"></v-switch>
        <v-switch v-model="valuesRegister.remision" :label="`Requiere remisión`" style="width: 300px; float: left;"></v-switch>
        <v-select
          :items="tiemposDePago"
          v-model="valuesRegister.tiempoPago"
          label="Tiempo de pago"
          outlined
          style="width: 200px; float: left;"
        ></v-select>
      </middle-box>

      <middle-box>
        <h2>Contacto</h2>
        <BlitzForm
          :schema="schemaRegisterUsuario"
          v-model="valuesRegisterUsuario"
          gridGap="0"
          labelPosition="left"
        />

        <h2>Persona responsable de solicitudes</h2>
        <p>*Debe tener diferente información al contacto*</p>
        <BlitzForm
          :schema="schemaRegisterUsuario"
          v-model="valuesRegisterUsuarioResponsable"
          gridGap="0"
          labelPosition="left"
        />

        <h2>Contacto de Cartera o pagos</h2>
        <BlitzForm
          :schema="schemaContactoCartera"
          v-model="valuesContactoCartera"
          gridGap="0"
          labelPosition="left"
        />

        <br />
      </middle-box>
      <FullBoxVue>
        <h2>Costos de subservicios</h2>
        <br />

        <v-autocomplete
          v-model="preServiciosSelected"
          :items="servicios"
          color="blue-grey lighten-2"
          class="seleccionSubservicios"
          label="Seleccionar subservicio"
          item-text="NOMBRE_SUBSERVICIO"
          item-value="ID_SUBSERVICIO"
          chips
          closable-chips
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item.NOMBRE_SUBSERVICIO }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.NOMBRE_SUBSERVICIO"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-text-field
          label="Precio"
          prefix="$"
          type="number"
          v-model="precio"
          outlined
          class="campoPrecio"
        ></v-text-field>

        <v-text-field
          label="Observaciones"
          type="text"
          v-model="observaciones"
          outlined
          class="campoObservaciones"
        ></v-text-field>

        <v-btn
          color="primary"
          @click="agregarPrecio"
          class="botonAgregarPrecio"
          :disabled="preServiciosSelected == 0 || precio == null || precio == 0"
        >
          Agregar
        </v-btn>

        <br />
      </FullBoxVue>
    </div>

    <div>
      <h2>Costos seleccionados</h2>
      <br />
      <div v-for="(objeto, index) in tablasPrecios" :key="index">
        <div class="seleccionSubservicios">
          {{ getNombreServicio(objeto.idServicio) }}
        </div>
        <v-text-field
          label="Precio"
          prefix="$"
          type="number"
          v-model="objeto.costo"
          outlined
          class="campoPrecio"
        ></v-text-field>

        <v-text-field
          label="Observaciones"
          type="text"
          v-model="objeto.observaciones"
          outlined
          class="campoObservaciones"
        ></v-text-field>

        <v-btn
          color="primary"
          @click="eliminarPrecio(index)"
          class="botonAgregarPrecio"
        >
          Eliminar
        </v-btn>
      </div>
    </div>
    <br />

    <FullBoxVue class="shadowHover">
      <v-alert
        v-if="errorRequeridos != ''"
        title="Hay errores en su formulario"
        type="error"
      >
        <span v-html="errorRequeridos"></span
      ></v-alert>
      <div>
        <v-btn
          color="primary"
          @click="enviarFormulario"
          :disabled="errorRequeridos != ''"
        >
          Crear
        </v-btn>
      </div>
    </FullBoxVue>

  </FullBoxVue>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";
import MiddleBox from "@/components/static/MiddleBox.vue";
import { BlitzForm } from "blitzar";
import searchUserMultipleVue from "../components/searchUserMultiple.vue";
import Vue from "vue";

import {
  VTextField,
  VRadioGroup,
  VRadio,
  VSlider,
  VCheckbox,
  VSwitch,
  VSelect,
} from "vuetify/lib";
// All components that are used in the form need to be globally registered.
Vue.component("VTextField", VTextField);
Vue.component("VRadioGroup", VRadioGroup);
Vue.component("VRadio", VRadio);
Vue.component("VSlider", VSlider);
Vue.component("VCheckbox", VCheckbox);
Vue.component("VSwitch", VSwitch);
Vue.component("VSelect", VSelect);

import {
  getDocumento,
  postUser,
  getRoles,
  getAllSubServicio,
  getClientes,
  getDepartamento,
  getCiudad,
} from "@/api";

const valuesRegisterdef = {
  nombre: "",
  tipoDocumento: "",
  contacto: "",
  correo: "",
  documento: "",
  rol: 8,
  fechaCorte: "",
  fechaRemision: "",
  tiempoPago: "30",
  costos: 0,
};

const tenPercent = "width: 18%;max-width: 18%;margin: 1%;";
const tenPercentWFloat = "width: 18%;max-width: 18%;margin: 1%; float: left;";

const twentyPercent = "width: 18%;max-width: 18%;margin: 1%;";
const twentyPercentWFloat =
  "width: 18%;max-width: 18%;margin: 1%; float: left;";

const thirtyPercent = "width: 28%;max-width: 28%;margin: 1%;";
const thirtyPercentWFloat =
  "width: 28%;max-width: 28%;margin: 1%; float: left;";

const thirtytreePercent = "width: 32.333%;max-width: 32.333%;margin: 1%;";
const thirtytreePercentWFloat =
  "width: 31.333%;max-width: 31.333%;margin: 1%; float: left;";

const fourtyPercent = "width: 38%;max-width: 38%;margin: 1%;";
const fourtyPercentWFloat =
  "width: 38%;max-width: 38%;margin: 1%; float: left;";

const fiftyPercent = "width: 48%;max-width: 48%;margin: 1%;";
const fiftyPercentWFloat = "width: 48%;max-width: 48%;margin: 1%; float: left;";

const sixtyPercent = "width: 58%;max-width: 58%;margin: 1%;";
const sixtyPercentWFloat = "width: 58%;max-width: 58%;margin: 1%; float: left;";

const seventyPercent = "width: 68%;max-width: 68%;margin: 1%;";
const seventyPercentWFloat =
  "width: 68%;max-width: 68%;margin: 1%; float: left;";

const hundredPercent = "width: 98%;max-width: 98%;margin: 1%;";
const hundredPercentWFloat =
  "width: 98%;max-width: 98%;margin: 1%; float: left;";

export default {
  data() {
    return {
      errorRequeridos: "",
      tiemposDePago: [
        "Anticipado",
        "30",
        "45",
        "60",
        "90"
      ],
      componentKeyUbicacion: -1,
      valuesUbicacion: {
        departamento: "",
        ciudad: "",
      },
      valuesRegister: {
        nombre: "",
        tipoDocumento: "",
        documento: "",
        contacto: "",
        correo: "",
        representante_legal: "",
        direccion: "",
        fechaCorte: "",
        fechaRemision: "",
        rol: 8,
        userSearchSelected: [],
        costos: [],
        correoFacturacionElectronica: "",
        ordenCompra: false,
        remision: false,
        tiempoPago: "30",
        observaciones: "",
      },
      preServiciosSelected: 0,
      menu: false,
      serviciosDefault: [],
      schemaRegisterUsuario: [
        {
          id: "nombre",
          component: "VTextField",
          label: "Nombre*",
          style: fiftyPercentWFloat,
          isRequired: true,
        },
        //Id Tipo Documento
        {},
        {
          id: "documento",
          component: "VTextField",
          label: "Documento*",
          style: thirtyPercentWFloat,
          isRequired: true,
        },
        {
          id: "contacto",
          component: "VTextField",
          label: "Teléfono*",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },
        {
          id: "correo",
          component: "VTextField",
          label: "Correo*",
          style: sixtyPercentWFloat,
          isRequired: true,
        },
        {
          id: "cargo",
          component: "VTextField",
          label: "Cargo*",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },
        //Info contacto
        {},
        //Tipo de rol
        {},
      ],
      schemaContactoCartera: [
        {
          id: "nombre",
          component: "VTextField",
          label: "Nombre*",
          style: fiftyPercentWFloat,
        },
        {
          id: "contacto",
          component: "VTextField",
          label: "Teléfono*",
          style: thirtytreePercentWFloat,
        },
        {
          id: "correo",
          component: "VTextField",
          label: "Correo",
          style: sixtyPercentWFloat,
        },
      ],
      schemaRegister: [
        {
          id: "nombre",
          component: "VTextField",
          placeholder: "Razón Social*",
          style: fiftyPercentWFloat,
          isRequired: true,
        },
        //Id Tipo Documento
        {},
        {
          id: "documento",
          component: "VTextField",
          placeholder: "No. Cedula / NIT*",
          style: thirtyPercent,
          isRequired: true,
        },
        {
          id: "contacto",
          component: "VTextField",
          placeholder: "Teléfono*",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },
        {
          id: "correo",
          component: "VTextField",
          placeholder: "Correo*",
          style: sixtyPercentWFloat,
          isRequired: true,
        },
        {
          id: "correoFacturacionElectronica",
          component: "VTextField",
          placeholder: "Correo facturación electrónica*",
          style: sixtyPercentWFloat,
          isRequired: true,
        },

        {
          id: "representante_legal",
          component: "VTextField",
          placeholder: "Representante Legal*",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },
        {
          id: "direccion",
          component: "VTextField",
          placeholder: "Dirección de empresa*",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },
        {
          id: "fechaCorte",
          component: "VTextField",
          type: "number",
          placeholder: "Día mes para corte*",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },
        {
          id: "fechaRemision",
          component: "VTextField",
          type: "number",
          placeholder: "Día mes para Remisión*",
          style: thirtytreePercentWFloat,
          isRequired: true,
        },
        {
          id: "observaciones",
          component: "VTextField",
          placeholder: "Otras observaciones",
          style: hundredPercent,
        },
      ],
      componentKey: 0,
      costo: 0,
      userSearch: [],
      servicios: [],
      costosServicios: [],
      valuesRegisterUsuario: {
        nombre: "",
        tipoDocumento: "",
        documento: "",
        contacto: "",
        correo: "",
        representante_legal: "",
        direccion: "",
        fechaCorte: "",
        fechaRemision: "",
        rol: 8,
        userSearchSelected: [],
        costos: [],
        cargo: "",
      },
      valuesRegisterUsuarioResponsable: {
        nombre: "",
        tipoDocumento: "",
        documento: "",
        contacto: "",
        correo: "",
        representante_legal: "",
        direccion: "",
        fechaCorte: "",
        fechaRemision: "",
        rol: 8,
        userSearchSelected: [],
        costos: [],
        cargo: "",
      },
      valuesContactoCartera: {
        nombre: "",
        contacto: "",
        correo: "",
      },
      precio: 0,
      observaciones: "",
      tablasPrecios: [],
      schemaUbicacion: [],
    };
  },

  methods: {
    agregarPrecio() {
      for (let i in this.tablasPrecios) {
        if (this.tablasPrecios[i].idServicio == this.preServiciosSelected) {
          this.tablasPrecios[i] = {
            idServicio: this.preServiciosSelected,
            costo: this.precio,
            observaciones: this.observaciones,
          };
          this.recargarFormulario();
          return;
        }
      }

      this.tablasPrecios.push({
        idServicio: this.preServiciosSelected,
        costo: this.precio,
        observaciones: this.observaciones,
      });
    },

    myEventHandler(e) {
      if (e.currentTarget.innerWidth <= 600) {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }
    },

    userSearchSelectedFunc(values) {
      this.valuesRegister.userSearchSelected = values;
    },
    recargarFormulario() {
      this.componentKey -= 1;
    },

    updateCosto(value, index, servicioSel) {
      let totalAumentar = 0;
      for (let i in this.servicios) {
        if (i == servicioSel) {
          break;
        }
        totalAumentar += this.servicios[i].length;
      }
      this.costosServicios[totalAumentar + index].costo = Number(value);
      this.valuesRegister.costos = JSON.parse(
        JSON.stringify(this.costosServicios)
      );
    },

    getNombreServicio(id) {
      for (let i in this.servicios) {
        if (this.servicios[i].ID_SUBSERVICIO == id) {
          return this.servicios[i].NOMBRE_SUBSERVICIO;
        }
      }
    },

    eliminarPrecio(id) {
      this.tablasPrecios.splice(id, 1);
    },

    enviarFormulario() {
      this.valuesRegister.contactoUser = this.valuesRegisterUsuario;
      this.valuesRegister.responsableUser =
        this.valuesRegisterUsuarioResponsable;
      this.valuesRegister.contactoCarteraOPagos = this.valuesContactoCartera;
      this.valuesRegister.costos = this.tablasPrecios;
      this.valuesRegister.ubicacion = this.valuesUbicacion;
      postUser(this.valuesRegister)
        .then(
          function (response) {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            if (response.data == "") {
              Toast.fire({
                icon: "success",
                title: "Usuario registrado exitosamente",
              });
              this.valuesRegister = valuesRegisterdef;
              this.recargarFormulario();
            } else {
              Toast.fire({
                icon: "error",
                title:
                  response.data,
              });
            }
          }.bind(this)
        )
        .catch(function (response) {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "error",
            title:
              "Error al registrar el usuario, correo no válido y/o ya usado.",
          });
        });
    },

    consultaSubServicios() {
      getAllSubServicio().then(
        function (response) {
          this.servicios = response.data;

          this.costosServicios = [];
          for (let key in this.servicios) {
            for (let i = 0; i < this.servicios[key].length; i++) {
              this.costosServicios.push({
                idServicio: this.servicios[key][i].ID_SUBSERVICIO,
                costo: 0,
              });
            }
          }
          this.valuesRegister.costos = JSON.parse(
            JSON.stringify(this.costosServicios)
          );
        }.bind(this)
      );
    },

    consultaCiudad(id) {
      getCiudad(id).then(
        function (response) {
          const indice = 1;
          if (response.data.length > 0) {
            const itemsOptionsTemplate = {
              value: "",
              text: "Seleccione la ciudad",
              disabled: true,
            };

            const options = [itemsOptionsTemplate];

            for (let i = 0; i < response.data.length; i++) {
              // Solución planteada así por un error de referencia de memoria
              const itemsOptionsTemplate = {
                value: "",
                text: "",
              };
              const subTemplate = itemsOptionsTemplate;
              itemsOptionsTemplate.value = response.data[i].ID_CIUDAD;
              subTemplate.text = response.data[i].NOMBRE_CIUDAD;
              options.push(subTemplate);
            }

            if (this.schemaUbicacion[indice] == null) {
              this.schemaUbicacion.splice(indice, 0, {
                id: "ciudad",
                span: true,
                component: "VSelect",
                placeholder: "Ciudad*",
                items: options,
                style: fiftyPercentWFloat,
                isRequired: true,
              });
            } else {
              this.schemaUbicacion[indice] = {
                id: "ciudad",
                span: true,
                component: "VSelect",
                placeholder: "Ciudad*",
                items: options,
                style: fiftyPercentWFloat,
                isRequired: true,
              };
            }
          } else {
            this.schemaUbicacion.splice(indice, 1);
          }
          this.recargarFormulario();
        }.bind(this)
      );
    },

    validarForm(schema, values) {
      let returnValue = true;
      for (let i = 0; i < schema.length; i++) {
        let element = schema[i];
        if (element.isRequired) {
          if (
            values[element.id] == "" ||
            values[element.id] === null ||
            values[element.id].length === 0
          ) {
            if (this.errorRequeridos == "") {
              if(element.placeholder){
                this.errorRequeridos =
                  " El campo " + element.placeholder + " es requerido <br>";
              }else{
                this.errorRequeridos =
                  " El campo " + element.label + " es requerido <br>";
              }
            } else {
              if(element.placeholder){
                this.errorRequeridos +=
                  " El campo " + element.placeholder + " es requerido <br>";
              }else{
                this.errorRequeridos +=
                  " El campo " + element.label + " es requerido <br>";
              }
            }
            returnValue = false;
          }
        }
      }
      return returnValue;
    },

    validarFormularios() {
      this.errorRequeridos = "";
      this.validarForm(this.schemaRegister, this.valuesRegister)
      this.validarForm(this.schemaUbicacion, this.valuesUbicacion)
      this.validarForm(this.schemaRegisterUsuario, this.valuesRegisterUsuario)
      this.validarForm(this.schemaRegisterUsuario, this.valuesRegisterUsuarioResponsable)
    },
  },

  mounted() {

    const { offsetWidth, offsetHeight } = document.querySelector("#app");
    if (offsetWidth <= 600) {
      setTimeout(function () {
        let classNames = document.getElementsByClassName("blitz-field");
        for (let i = 0; i < classNames.length; i++) {
          document.getElementsByClassName("blitz-field")[i].style = "";
        }
      }, 200);
    }

    let classNames = document.getElementsByClassName("blitz-form__form");
    for (let i = 0; i < classNames.length; i++) {
      document.getElementsByClassName("blitz-form__form")[i].style =
        "display: block";
    }

    getClientes().then(
      function (response) {
        this.userSearch = response.data;
      }.bind(this)
    );

    getDepartamento().then(
      function (response) {
        const indice = 0;
        const totalForms = 5;
        const itemsOptionsTemplate = {
          value: "",
          text: "Seleccione el departamento",
          disabled: true,
        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_DEPARTAMENTO;
          subTemplate.text = response.data[i].NOMBRE_DEPARTAMENTO;
          options.push(subTemplate);
        }

        if (this.schemaUbicacion[indice] == null) {
          this.schemaUbicacion.splice(indice, 0, {
            id: "departamento",
            span: true,
            component: "VSelect",
            placeholder: "Departamento*",
            items: options,
            style: fiftyPercentWFloat,
            isRequired: true,
          });
        } else {
          this.schemaUbicacion[indice] = {
            id: "departamento",
            span: true,
            component: "VSelect",
            placeholder: "Departamento*",
            items: options,
            style: fiftyPercentWFloat,
            isRequired: true,
          };
        }
        this.recargarFormulario();
        this.validarFormularios();
      }.bind(this)
    );

    getDocumento().then(
      function (response) {
        const indice = 1;
        const itemsOptionsTemplate = {
          value: "",
          text: "T. Documento",
          disabled: true,
        };

        const options = [itemsOptionsTemplate];

        for (let i = 0; i < response.data.length; i++) {
          // Solución planteada así por un error de referencia de memoria
          const itemsOptionsTemplate = {
            value: "",
            text: "",
          };
          const subTemplate = itemsOptionsTemplate;
          itemsOptionsTemplate.value = response.data[i].ID_TIPO_DOCUMENTO;
          subTemplate.text = response.data[i].DESC_TIPO_DOCUMENTO;
          options.push(subTemplate);
        }

        let object = {
          id: "tipoDocumento",
          span: true,
          component: "VSelect",
          placeholder: "Tipo de documento",
          style: tenPercentWFloat,
          items: options,
        };

        this.schemaRegister[indice] = object;
        this.schemaRegisterUsuario[1] = object;

        this.recargarFormulario();
      }.bind(this)
    );
    this.consultaSubServicios();
  },

  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },

  watch: {
    valuesUbicacion(newValue, oldValue) {
      if (
        newValue.departamento &&
        newValue.departamento != oldValue.departamento
      ) {
        this.valuesUbicacion.ciudad = "";
        this.consultaCiudad(newValue.departamento);
      }
      this.validarFormularios();
    },
    valuesRegister(newValue, oldValue) {
      this.valuesRegister.serviciosAsociados = oldValue.serviciosAsociados;
      this.valuesRegister.userSearchSelected = oldValue.userSearchSelected;
      this.valuesRegister.tiempoPago = oldValue.tiempoPago;
      this.valuesRegister.ordenCompra = oldValue.ordenCompra
      this.valuesRegister.remision = oldValue.remision
      this.valuesRegister.costos = oldValue.costos;

      const regex = /^[0-9]+$/;

      if(parseInt(this.valuesRegister.fechaCorte) < 1 || !regex.test(this.valuesRegister.fechaCorte)){
        this.valuesRegister.fechaCorte = 1;
        this.recargarFormulario();
      }else if(parseInt(this.valuesRegister.fechaCorte) > 28){
        this.valuesRegister.fechaCorte = 28;
        this.recargarFormulario();
      }

      if(parseInt(this.valuesRegister.fechaRemision) < 1 || !regex.test(this.valuesRegister.fechaRemision)){
        this.valuesRegister.fechaRemision = 1;
        this.recargarFormulario();
      }else if(parseInt(this.valuesRegister.fechaRemision) > 28){
        this.valuesRegister.fechaRemision = 28;
        this.recargarFormulario();
      }

      this.validarFormularios();
    },
    valuesRegisterUsuario(newValue, oldValue) {
      this.validarFormularios();
    },
    valuesRegisterUsuarioResponsable(newValue, oldValue) {
      this.validarFormularios();
    },
  },

  components: {
    FullBoxVue,
    MiddleBox,
    BlitzForm,
    searchUserMultipleVue,
  },
};
</script>

<style>
.tirthyPercent {
  width: 23%;
  max-width: 28%;
  margin: 1%;
  float: left;
}

.blitz-form__form {
  display: block !important;
}

.formContent {
  display: flow-root;
}

.seleccionSubservicios {
  width: 38%;
  margin: 1%;
  padding: 3px;
  float: left;
}

.campoPrecio {
  width: 18%;
  margin: 1%;
  float: left;
}

.campoObservaciones {
  width: 28%;
  margin: 1%;
  float: left;
}

.botonAgregarPrecio {
  width: 13%;
  margin: 0.5%;
  float: left;
}
@media (max-width: 700px) {
  .seleccionSubservicios {
    width: 100%;
  }

  .campoPrecio {
    width: 100%;
  }

  .campoObservaciones {
    width: 100%;
  }

  .botonAgregarPrecio {
    width: 100%;
  }
}
</style>
