<template>
  <div>
    <FullBoxVue class="shadowHover">
      <h1>Modificar servicios</h1>
      <br />
      <v-btn color="primary" dark @click="showModal = true"
        >Registrar nuevo servicio</v-btn
      >

      <v-dialog v-model="showModal" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Ingrese el nombre del nuevo servicio</span>
          </v-card-title>

          <v-card-text>
            <v-text-field
              label="Nombre de servicio"
              v-model="newData"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showModal = false"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveData">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <br /><br />
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="serviciosDefault"
          :search="search"
        >
          <template v-slot:body="{ items, headers }">
            <tbody>
              <tr v-for="(item, idx, k) in items" :key="idx">
                <td v-for="(header, key) in headers" :key="key">
                  <v-edit-dialog
                    :return-value.sync="item[header.value]"
                    @save="save(item)"
                    save-text="Guardar"
                    @cancel="cancel"
                    cancel-text="Cancelar"
                    @open="open"
                    @close="close"
                    large
                  >
                    {{ item[header.value] }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item[header.value]"
                        label="Nombre solicitud"
                        single-line
                      ></v-text-field>
                      <v-textarea
                        v-model="item.documentos_requeridos"
                        label="Documentos Requeridos para el candidato"
                      ></v-textarea>
                      <h3>Autorización</h3>
                      <input type="file" @change="onFileChange($event, index)" />
                      <br><br>
                      <v-btn :color="'error'" @click="habilitarServicio(item)">
                        Deshabilitar servicio
                      </v-btn>
                      <br><br>
                      <v-btn
                        color="primary"
                        outlined
                        @click="seleccionarPeticion(item)"
                        >Mirar subservicios</v-btn
                      >
                    </template>
                  </v-edit-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </FullBoxVue>
    <FullBoxVue class="shadowHover">
      <h1>Servicios deshabilitados</h1>
      <br /><br />
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="serviciosDeshabilitadosDefault"
          :search="search"
        >
          <template v-slot:body="{ items, headers }">
            <tbody>
              <tr v-for="(item, idx, k) in items" :key="idx">
                <td v-for="(header, key) in headers" :key="key">
                  <v-edit-dialog
                    :return-value.sync="item[header.value]"
                    @save="save(item)"
                    save-text="Guardar"
                    @cancel="cancel"
                    cancel-text="Cancelar"
                    @open="open"
                    @close="close"
                    large
                  >
                    {{ item[header.value] }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item[header.value]"
                        label="Nombre solicitud"
                        single-line
                      ></v-text-field>
                      <v-textarea
                        v-model="item.documentos_requeridos"
                        label="Documentos Requeridos para el candidato"
                      ></v-textarea>
                      <v-btn :color="'primary'" @click="habilitarServicio(item)">
                        Habilitar servicio
                      </v-btn>
                      <br><br>
                      <v-btn
                        color="primary"
                        outlined
                        @click="seleccionarPeticion(item)"
                        >Mirar subservicios</v-btn
                      >
                    </template>
                  </v-edit-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </FullBoxVue>
  </div>
</template>

<script>
import FullBoxVue from "../components/static/FullBox.vue";

import {
  getServicio,
  newServicio,
  updateServicio,
  getServicioDeshabilitados,
  habilitarServicio
} from "../api";

export default {
  data() {
    return {
      search: "",
      headers: [{ text: "Nombre del servicio", value: "nombre" }],
      serviciosDefault: [],
      serviciosDeshabilitadosDefault: [],
      idSolicitudSeleccionada: {},
      archivosSeleccionados: [{}],
      infoSolicitudRef: null,
      showModal: false,
      costo: "",
      documentosRequeridos: "",
      documentosRequeridosPre: "",
      newData: "",
      index: 0,
    };
  },
  components: {
    FullBoxVue,
  },
  methods: {
    onFileChange(event, index) {
      let file = event.target.files[0];
      this.archivosSeleccionados[index] = file;
    },

    consultaServicio() {
      getServicio().then(
        function (response) {
          this.preServicios = response.data;
          this.serviciosDefault = [];
          for (let i = 0; i < this.preServicios.length; i++) {
            this.serviciosDefault.push({
              id: this.preServicios[i].ID_SERVICIO,
              nombre: this.preServicios[i].NOMBRE_SERVICIO,
              documentos_requeridos: this.preServicios[
                i
              ].DOCUMENTOS_REQUERIDOS.replace(/<br>/g, "\n"),
            });
          }
        }.bind(this)
      );
    },

    consultaServicioDeshabilitado() {
      getServicioDeshabilitados().then(
        function (response) {
          this.preServicios = response.data;
          this.serviciosDeshabilitadosDefault = [];
          for (let i = 0; i < this.preServicios.length; i++) {
            this.serviciosDeshabilitadosDefault.push({
              id: this.preServicios[i].ID_SERVICIO,
              nombre: this.preServicios[i].NOMBRE_SERVICIO,
              documentos_requeridos: this.preServicios[
                i
              ].DOCUMENTOS_REQUERIDOS.replace(/<br>/g, "\n"),
            });
          }
        }.bind(this)
      );
    },

    seleccionarPeticion(option) {
      this.idSolicitudSeleccionada = option;
      this.$router
        .push({ path: "/servicio/" + this.idSolicitudSeleccionada.id })
        .catch(() => {});
    },

    habilitarServicio(item){
      habilitarServicio({'idServicio':item.id}).then(
        function (response){
          window.location.reload();
        }.bind(this)
      )
    },

    saveData() {
      newServicio({
        nombre: this.newData,
      }).then(
        function (response) {
          window.location.reload();
        }.bind(this)
      );
    },

    updateText(item) {
      item.documentos_requeridos = item.documentos_requeridos.replace(
        /\n/g,
        "<br>"
      );
    },

    save(item) {
      item.documentos_requeridos = item.documentos_requeridos.replace(
        /<br>/g,
        "\n"
      );

      const formData = new FormData();
      formData.append("file", this.archivosSeleccionados[0]);
      formData.append("item", JSON.stringify(item));
      updateServicio(formData).then(
        function (response) {
          window.location.reload();
        }.bind(this)
      );
    },
    cancel() {},
    open() {},
    close() {},
  },
  mounted() {
    this.consultaServicio();
    this.consultaServicioDeshabilitado();
  },
};
</script>

<style scoped>
div {
  width: 100%;
}
</style>
