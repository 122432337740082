<template>
  <div v-if="campo.tipo === 'texto'">
    <v-text-field
      :label="campo.label"
      v-model="campo.valor"
      :prefix="campo.simbolo? campo.simbolo : ''"
      :rules="campo.obligatorio? reglas : sinReglas"
      :disabled="campo.noEditable">
      <template slot="append" v-if="campo.obligatorio">
        <div class="red--text">*</div>
      </template>
    </v-text-field>
  </div>
  <div v-else-if="campo.tipo === 'numero'">
    <v-text-field
      v-model="campo.valor"
      :label="campo.label"
      @input="formateoMiles()"
      onkeypress="return (event.charCode >= 48 &&  event.charCode <= 57)"
      :prefix="campo.simbolo? campo.simbolo : ''"
      :disabled="campo.noEditable"
      :rules="campo.obligatorio? reglas : sinReglas">
        <template slot="append" v-if="campo.obligatorio">
          <div class="red--text">*</div>
        </template>
    </v-text-field>
  </div>
  <div v-else-if="campo.tipo === 'desplegable'">
    <v-select
      :items="campo.lista"
      :label="campo.label"
      v-model="campo.valor"
      :rules="campo.obligatorio? reglas : sinReglas"
      >
      <template slot="append" v-if="campo.obligatorio">
        <div class="red--text">*</div>
      </template>
    </v-select>
  </div>
  <div v-else-if="campo.tipo === 'fecha'">
    <CampoFecha  v-on:input="updateValor" :valor="campo.valor" :label="campo.label" :rules="campo.obligatorio? reglas : sinReglas" />
  </div>
  <div v-else-if="campo.tipo === 'textoLargo'">
    <v-textarea
    :label="campo.label"
    v-model="campo.valor"
    :rules="campo.obligatorio? reglas : sinReglas"
    :disabled="campo.noEditable"
    >
    <template slot="append" v-if="campo.obligatorio">
      <div class="red--text">*</div></template>
    </v-textarea>
  </div>
  <div v-else-if="campo.tipo === 'form'">
    <CreateForm
      :idIteracion="idIteracion + i.toString()"
      :idForm="idForm"
      :titulo="campo.form.titulo"
      :campos="campo.form.campos"/>
  </div>
  <div v-else-if="campo.tipo === 'tabla'">
    <CreateTable
      class="tabla"
      :autoIncrementable="campo.autoIncrementable"
      :idIteracion="idIteracion + i.toString()"
      :cabeceras='campo.cabeceras'
      :label='campo.label'
      :formato="campo.formato"
      :lista="campo.listaValores"
      :columnasPeque="campo.columnasPeque"
      :noEditable="campo.noEditable"
      :listaTotales="campo.listaTotales"
      :idSolicitud="idSolicitud"/>

  </div>
  <div v-else-if="campo.tipo === 'archivo'">

    <CampoArchivo
      :idIteracion="idIteracion + i.toString()"
      :label="campo.label"
      :idSolicitud="idSolicitud"
      :permitido="campo.permitido"
      :oneFile=false
      :campo="campo"
      :muestraLabel="muestraLabel"
      />
  </div>
  <div v-else-if="campo.tipo === 'tablaComparativa'">
    <CreateTableComparative
      class="tabla"
      :idIteracion="idIteracion + i.toString()"
      :idForm="idForm"
      :label="campo.label"
      :cabeceras="campo.cabeceras"
      :forms="campo.forms"
      :columnasPeque="campo.columnasPeque"
      />
  </div>
  <div v-else-if="campo.tipo === 'completarTexto'">
    <CompleteText
      :secuencias="campo.secuencias" ></CompleteText>
  </div>
  <div v-else-if="campo.tipo === 'inf'" style="text-align: left;font-size: larger;">
    <strong>
      <template>
        {{ campo.valor }}
      </template>
    </strong>
  </div>
  <div v-else-if="campo.tipo === 'duplicaCampos'" style="text-align: left;font-size: larger;">
    <DuplicaCampos
    :campo="campo"
    :idIteracion="idIteracion + i.toString()"
    :idForm="idForm"
    :label="campo.label"
    :cabeceras="campo.cabeceras"
    :forms="campo.forms"></DuplicaCampos>
  </div>
  <div  v-else-if="campo.tipo === 'entrevista'">
    <CampoEntrevista :campo="campo"></CampoEntrevista>
  </div>
  <div  v-else-if="campo.tipo === 'evaluacionPoligrafia'">
    <evaluacionPoligrafia :campo="campo"></evaluacionPoligrafia>
  </div>
  <div  v-else-if="campo.tipo === 'ConceptoGeneralVisita'">
    <ConceptoGeneralVisita :campo="campo"/>
  </div>
</template>
<script>
import CampoFecha from './static/CampoFecha.vue';
import CreateTable from './CreateTable.vue';
import CreateTableComparative from './CreateTableComparative.vue';
import CampoArchivo from './static/CampoArchivo.vue';
import CreateForm from './CreateForm.vue';
import CompleteText from './static/CompleteText.vue';
import CampoEntrevista from './static/CampoEntrevista.vue';
import DuplicaCampos from './static/DuplicaCampos.vue';
import evaluacionPoligrafia from './static/evaluacionPoligrafia.vue'
import ConceptoGeneralVisita from './static/ConceptoGeneralVisita.vue'
import { getDepartamento } from '@/api';
import { getCiudad } from '@/api';

export default ({
  props: {
    campo: {
      require: true
    },
    idForm: {
      require: true
    },
    idIteracion: {
      default: 0
    },
    i: {
      require: true
    },
    idSolicitud: {
      require: true
    },
    muestraLabel: {
      default: true
    }
  },
  components: {
    CampoFecha,
    CreateTable,
    CreateTableComparative,
    CampoArchivo,
    CreateForm,
    CompleteText,
    CampoEntrevista,
    DuplicaCampos,
    evaluacionPoligrafia,
    ConceptoGeneralVisita },
  name: 'RetornoCampo',
  created() {
    //generar desplegable para ciudad, departamento y documento
    if (this.campo.tipo === 'desplegable'){
      if (this.campo.lista === 'ciudad') {
        this.campo.lista = []
        getDepartamento()
          .then(response=> {
            return response.data
          })
          .then(response =>{
            response.forEach(departamento => {
              getCiudad(departamento.ID_DEPARTAMENTO)
                .then(response2 => {
                  return response2.data
                })
                .then(response2 => {
                  response2.forEach(ciudad =>{
                    this.campo.lista.push(ciudad.NOMBRE_CIUDAD)
                  })
                  this.campo.lista = this.campo.lista.sort()
                })
            });
          })
      } else if (this.campo.lista === 'departamento') {
        this.campo.lista= []
        getDepartamento()
        .then(response => {
          return response.data
        })
        .then(response => {
          response.forEach(deparatmento => {
            this.campo.lista.push(deparatmento.NOMBRE_DEPARTAMENTO)
          })
          this.campo.lista = this.campo.lista.sort()
        })
      } else if (this.campo.lista === 'documento') {
        this.campo.lista = ['CC','TI','RC','TE','CE','NIT','PAS','PEP','otro']
      }
    }
    //capitalizar  label
    this.campo.label = this.capitalizar(this.campo.label)


    if (this.campo.tipo === 'tabla') {
      //capitalización cabeceras
      this.campo.cabeceras.forEach( (cabecera, index) => {
        this.campo.cabeceras[index] = this.capitalizar(cabecera);
      })
    }else if (this.campo.tipo === 'desplegable') {
      //capitalización listas
      this.campo.lista.forEach( (opc, index) => {
        this.campo.lista[index] = this.capitalizar(opc);
      })
    }

  },
  data: () => ({
    acivarExlusion: false,
    reglas: [
      value => !!value || 'Este campo es obligatorio'
    ],
    sinReglas: [],
    noCapitalizar: ['de','y','al','que','en','o','a'],
  }),
  methods: {
    capitalizar: function(frase) {
      if (frase){
        let palabras = frase.split(' ');

        palabras.forEach((palabra, index) => {
          if (!this.noCapitalizar.includes(palabra)) {
            palabras[index] = palabra.charAt(0).toUpperCase() + palabra.slice(1);
          }
        });
        return palabras.join(' ');
      }
      return null

    },
    formateoMiles: function() {
      //quitar caracter diferentes a numeros
      let listaNumeros = this.campo.valor.split("")
      let nuevoTexto = ''

      listaNumeros.forEach(caracter => {
        if ( '0123456789'.includes(caracter)){
          nuevoTexto += caracter
        }
      })

      //2)poner comas
      this.campo.valor = nuevoTexto.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    updateValor(valor){
      this.campo.valor = valor
    }
  },
  watch: {
    'campo.valor': function () {
      if ((this.campo.tipo === 'texto' || this.campo.tipo === 'textoLargo') && this.campo.valor.length === 1) {
        this.campo.valor = this.campo.valor.toUpperCase();
      }

    }
  }
})
</script>
<style scoped>
.tabla {
  width: 100%;
  overflow-x: auto;
}
</style>
