// formatos de confiabilidad
import referenciaAntecedentes from "./formatosConfiabilidad/referenciaAntecedentes.js"
import referenciacion from "./formatosConfiabilidad/referenciacion.js"
import confiablilidadBasico from "./formatosConfiabilidad/confiabilidadBasico.js"
import DomiciliariaDeIngreso from "./formatosConfiabilidad/DomiciliariaDeIngreso.js"
import VisitaDominiciliariaSeguimiento from "./formatosConfiabilidad/VisitaDominiciliariaSeguimiento.js"
import estudioSocioEconomico from "./formatosConfiabilidad/estudioSocioEconomico.js"

// documentos informe o documentos 3
import VisitaAsociadosNegocios from "./formatosDeInforme/VisitaAsociadosNegocios.js"
import informeEjecutivoTipoA from "./formatosDeInforme/informeEjecutivoTipoA.js"
import informeEjecutivoTipoB from "./formatosDeInforme/informeEjecutivoTipoB.js"
import informeConsultas from "./formatosDeInforme/informeConsultas.js"

/////////////////////////////////////////////////////////////todos de poligrafia
// documentos poligrafia o documentos 2

import informeConfirmatorioPoligrafia from "./formatosPoligrafica/InformeConfirmatorioPoligrafia.js"////este ya
//correto en plataforma y completado
import informePruebaPoligrafiaEspecifico from "./formatosPoligrafica/InformePruebaPoligrafiaEspecifico.js"
//correto en plataforma y completado
import informePoligrafiaPreempleo from "./formatosPoligrafica/InformePoligrafiaPreempleo.js"////este ya
//correto en plataforma y completado
import informePruebaRutinaPoligrafia from "./formatosPoligrafica/InformePruebaRutinaPoligrafia.js"

///VSA
//correto completado
import informeEspecificoVSA from "./FormatosVSA/InformeEspecificoVSA.js"////este ya
//correto completado, falta subir a plataforma
import informePruebaPreenpleoVSA from "./FormatosVSA/InformePruebaPreenpleoVSA.js"
//correto completado, falta subir a plataforma
import informePruebaRutinaVSA from "./FormatosVSA/InformePruebaRutinaVSA.js"

export default({
  formularioPrueba: referenciacion,
  modoPrueba: false,
  copiarFormulario: false
})
