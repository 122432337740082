<template>
  <div class="app">
    <v-divider />
    <h2>{{ label }}</h2>
    <v-divider />
    
    <!-- Vista de Tarjetas para Móviles -->
    <div v-if="isMobile" class="mobile-view">
      <div class="card" v-for="(fila, i) in lista" :key="i">
        <div v-for="(campo, j) in fila" :key="j" class="card-field">
          <strong>{{ cabeceras[j] }}</strong>: 
          <RetornoCampo :campo="campo" :idSolicitud="idSolicitud" :muestraLabel="false" />
        </div>
        <div v-if="!noEditable" class="card-actions">
          <v-btn class="blue white--text" @click="borrarFila(i)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-if="!noEditable" class="card-actions">
        <v-btn class="blue white--text mx-5 my-5" @click="agregarOtro()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <!-- Tabla para Pantallas Grandes -->
    <div v-else class="table-container">
      <table class="tabla">
        <thead>
          <tr>
            <th v-for="(cabecera, i) in cabeceras" :key="i" class="text-left inlineBlock">
              {{ cabecera }}
            </th>
            <th v-if="!noEditable" class="filaBtn">
              <v-btn class="blue white--text" @click="agregarOtro()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody class="contenido">
          <tr v-for="(fila, i) in lista" :key="i">
            <td v-for="(campo, j) in fila" :key="j" :class="esPequeColumna(j)">
              <RetornoCampo :campo="campo" :idSolicitud="idSolicitud" :muestraLabel="false" />
            </td>
            <td v-if="!noEditable" class="filaBtn">
              <v-btn class="blue white--text" @click="borrarFila(i)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <v-divider />
  </div>
</template>

<script>
export default {
  props: {
    cabeceras: { default: [] },
    label: { default: '' },
    formato: { default: [] },
    lista: { default: [] },
    noEditable: { default: false },
    autoIncrementable: { default: false },
    idSolicitud: { default: '' },
    columnasPeque: {
      type: Array,
      default: () => []
    }
  },
  name: 'CreateTable',
  data() {
    return {
      isMobile: window.innerWidth <= 768
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    agregarOtro() {
      const nuevo = JSON.parse(JSON.stringify(this.formato));
      this.lista.push(nuevo);
      if (this.autoIncrementable) {
        this.reCargarAutoIncremento();
      }
    },
    borrarFila(i) {
      if (i >= 0 && i < this.lista.length) {
        this.lista.splice(i, 1);
      }
    },
    esPequeColumna(nColumna) {
      if (this.autoIncrementable && nColumna === 0) return 'columnaPeque';
      if (this.columnasPeque.includes(nColumna)) return 'columnapeque2';
      return '';
    }
  }
};
</script>

<style scoped>
/* Estilo para vista de tabla */
.table-container {
  overflow-x: auto;
}
.tabla {
  width: 100%;
  border-collapse: collapse;
}
.tabla th, .tabla td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}
.tabla th {
  min-width: 150px;
}
.tabla .columnaPeque {
  min-width: 50px;
}
.tabla .columnapeque2 {
  min-width: 250px;
}
.filaBtn {
  width: 80px;
}

/* Estilo para vista de tarjetas móviles */
.mobile-view .card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;
  background-color: #f9f9f9;
}
.mobile-view .card-field {
  margin-bottom: 8px;
}
.mobile-view .card-actions {
  text-align: right;
}
</style>
